import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, Container } from '@material-ui/core';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import navConfig1 from './MenuConfigMobile';
import DesktopMenuNew from './DesktopMenuNew';
import { MegaMenuDesktopHorizon, MegaMenuMobile, MenuConfig } from 'src/components/mega-menu';
import LOGO from '../../images/skyniche-logo.png'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;
const APP_BAR_DESKTOP_SMALL = 52;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_DESKTOP,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  },
}));
const ToolbarStyle1 = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_DESKTOP_SMALL,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP_SMALL
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const BlogInnerNew = pathname === '/empowering-business-transformation-with-ai';

  return (
    <>
    <AppBar sx={{ boxShadow: 0,height:'40px',backgroundColor:'#e8e8e6',display:{xs:'none',sm:'none',md:'block'},position: BlogInnerNew && 'static'  }}>
      <ToolbarStyle1
        disableGutters
        
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop:'-25px',
            justifyContent: 'flex-end',
            
          }}
        >

          <MHidden width="mdDown">
            <DesktopMenuNew isOffset={isOffset} isHome={isHome} />
          </MHidden>
        </Container>
      </ToolbarStyle1>

    </AppBar>
    
    {!BlogInnerNew ?(
      <AppBar color={'default'} sx={{ boxShadow: 0,marginTop:{xs:'0px',sm:'0px',md:'40px' }}}>
    {/* <AppBar color={isHome ? 'transparent' : 'default'} sx={{ boxShadow: 0 }}> */}
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            // height: { md: APP_BAR_DESKTOP - 10 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
          <img className='logo-responsive' src={LOGO} style={{cursor:'pointer'}} alt='Logo' />

          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            {/* <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} /> */}
            <MegaMenuDesktopHorizon navConfig={MenuConfig} />
          </MHidden>

          <MHidden width="mdUp">
            <MegaMenuMobile isOffset={isOffset} isHome={isHome} navConfig1={navConfig1} />
            {/* <MegaMenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} /> */}
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  ) : ""}
    </>
  );
}
